import React from 'react';

import {Link} from "react-router-dom";

import './RulesPage.css';
import PaymentProcess from "./PaymentProcess";

const RulesPage = () => <div className="rules">
	<h2>Общие правила:</h2>
	<p><b>1.1</b> Любой, играющий на проекте, автоматически соглашается с этим сводом правил и обязуется выполнять их.</p>
	<p><b>1.2</b> Администраторы игрового проекта имеет полное право заблокировать Ваш аккаунт за нарушение того или иного пункта правил.</p>
	<p><b>1.3</b> Администраторы игрового проекта имеет полное право аннулировать/заблокировать Ваш аккаунт без объяснения причин.</p>
	<p><b>1.4</b> Покупка товара осуществляется через заключение публичного <Link to="/offerAgreement" target="_blank">договора-оферты</Link>, и регламентируется его пунктами.</p>
	<p><b>1.5</b> Администрация не несет ответственности за неправильно переведенные средства.</p>
	<p><b>1.6</b> Администрация не обязана возвращать Вам то, что Вы утратили в следствие какой-либо технической/игровой ситуации.</p>
	<p><b>1.7</b> Данный свод правил может быть изменен в любой момент, и администрация оставляет на себе право не оповещать игроков об изменениях.</p>
	<p><b>1.8</b> Незнание правил не освобождает вас от ответственности. Взлом вашего аккаунта не является оправданием, если вас обвиняют в нарушении правил.</p>
	<p><b>1.9</b> Возврат средств происходит согласно пункту 8.4 публичного <Link to="/offerAgreement" target="_blank">договора-оферты</Link>.</p>
	<p><b>1.10</b> На обжалование бана дается 3 дня (Со дня получения бана).</p>
	<span>* Мут или предупреждение можно обжаловать до его конца.</span>
	<p><b>1.11</b> Админы не понимают шутки, рофлы, юмор и тд.</p>

	<h2>Запрещено:</h2>
	<p><b>2.1</b> Строительство нецензурных слов и символов, а также фигур, несущих порнографический характер.</p>
	<p><b>2.1.1</b> Скин содержащий нецензурные слова или имеет порнографический характер.</p>
	<p><b>2.2</b> Выдавать себя за члена администрации/модератора проекта, не являясь ее представителем.</p>
	<span>* Говорить что админка / модерка на втором аккаунте, вызывать на проверку и подобное.</span>
	<p><b>2.3</b> Устанавливать объекты, нагружающие работу серверного оборудования.</p>
	<p><b>2.3.1</b> Одновременное использование более 5-ти генераторов на одном острове скайблока.</p>
	<span>* Афк копание в генераторе, афк на фермах с ломанием/постановкой блоков или использованием предметов/инструментов разрешено не более 5 игроков с доната ниже рыцаря, свыше 5 - нужен донат рыцарь или выше.</span>
	<span>* Все твинки должны быть в составе острова.</span>
	<span>* Запрещено быть в составе других островов с твинков или основы, в том числе на другом скайблоке.</span>
	<span>* Запрещено добывание ресурсов в афк на спавне, в общем энде и в мирах ивентов на скайблоке.</span>
	<p><b>2.4</b> Использование и/или хранение стороннего ПО на версию 1.12+:</p>
	<span>- Чит-клиенты (Wurst, Impact, Inertia, Matix, Fatal Client, Celestial, Aristois, Zamorozka, Neverhook, Summit, Ares, Jessica и др.)</span>
	<span>- Читерные модификации (xray, Baritone, Freecam, HCsCR, Elytra/Chestplate Swapper, Auto Totem, CrystalOptimizer и др.)</span>
	<span>- Читерные ресурспаки/шейдеры (xray, с подсветкой энтити/блоков и др.)</span>
	<span>- Программы для инжекта (Java Injector, Extreme Injector и др.)</span>
	<span>- Макросы для ПВП (например на ломание щита, быструю смену оружия на зомби арене и др.)</span>
	<span>- Макросы автоматизирующие сложные игровые процессы (например на автоматическое передвижение игрока, нестандартное ломание блоков, открывание сундуков, автоматическое выполнение квестов и др.),</span>
	<span>  Разрешены: мини-карта, дамаг-индикатор, ресурспаки на геплы и прочность брони/оружия, кликер и моб аура (только на фермах). Также разрешено проходить жемчугом края через любые блоки, но бить через них (ломать и ударять) нельзя.</span>
	<span>* Не советуем что-то изменять или удалять в папке minecraft (или .prostocraft) когда вас вызвали на проверку, а также чистить корзину, загрузки, recent, shellbag, реестр, это может расцениваться как попытка удалить что-то запрещенное.</span>
	<span>* Модератор может потребовать пройти проверку (через Discord, AnyDesk, TeamViewer, на выбор модератора), а также скачать дополнительные программы для ее проведения. В случае отказа бан на 30 дней.</span>
	<span>* Если вы сознались в нарушении правила до начала проверки, то ваш срок бана будет сокращен на 7 дней. Признание после начала проверки никак не сокращает срок бана.</span>
	<p><b>2.4.2</b> Использование уязвимостей/багов сервера</p>
	<p><b>2.5</b> Любая передача/продажа аккаунта.</p>
	<span>* Наказание выдается по ситуации, от блокировки аккаунта до снятия внутриигровых привилегий.</span>
	<p><b>2.6</b> Любая торговля за не внутриигровые деньги, просьбы пополнить счет, кошелек и тд.</p>
	<p><b>2.7</b> Создавать помехи для работы модераторов/администрации.</p>
	<p><b>2.8</b> Нарушение развлекательных мероприятий на проекте/создание отвлекающих факторов для организаторов.</p>
	<p><b>2.9</b> Подстрекательство игроков на нарушение правил.</p>
	<p><b>2.10</b> Введение игроков в заблуждение.</p>
	<p><b>2.11</b> Гриферство, а также поиск его способов. (Заманивание в ловушки, взлом тайников на зомби арене входят сюда).</p>
	<span>* Добавляя в свой приват или остров игрока, вы разрешаете ему делать все что угодно. В случае гриферства игроком региона или острова - вещи возвращены не будут.</span>
	<span>* Если вас обманут на ресурсы во время обмена на вещи с другого режима или на покупку на сайте, то это считается гриферством.</span>
	<span>* Если на анархии обмен идет на вещи с другого режима или покупка с сайта, то это считается гриферством.</span>
	<p><b>2.12</b> Обходить бан / мут, играя на другом аккаунте, когда один из аккаунтов в бане.</p>
	<span>* бан по причине 2.13 не учитывается.</span>
	<p><b>2.13</b> Иметь оскорбительный / нецензурный / схожий с модерацией или администрацией ник. (Rigagit = _Rigagit_ ).</p>
	<span>* Также запрещено использовать слова или фразы, которые имеют отношение к другим проектам, читам, ресурсам, которые занимаются гемблингом (казино, рулетки) или запрещенным организациям на территории РФ.</span>
	<p><b>2.14</b> Взлом чужих аккаунтов, а так же привязка чужих аккаунтов.</p>
	<p><b>2.15</b> Абузы/дюпы наград на сайте/у бота группы.</p>
	<p><b>2.16</b> Тим с игроком, использующим и/или хранящим запрещенное ПО (передача ресурсов, совместная игра и др.)</p>

	<h2>Чат и общение</h2>
	<p><b>3.1</b> Оскорбление игроков любым способом.</p>
	<p><b>3.2</b> Флуд/повторение сообщений, символов. Интервал между однотипными сообщениями должен быть не менее ~30 секунд. Реклама какого-либо варпа / магазина и тому подобное раз в 3 минуты. Флуд символами считается от 5 символов и выше.</p>
	<p><b>3.3</b> Заведомо ложные показания о предвидящихся технических событиях на проекте.</p>
	<p><b>3.4</b> Использование ВЕРХНЕГО РЕГИСТРА (CAPS) в более 50% своего сообщения.</p>
	<p><b>3.5</b> Реклама любого ПО, игровых ресурсов, каналов, серверов, магазинов (упоминание может быть рассчитано рекламой)</p>
	<p><b>3.6</b> Мат в любом виде. (Учитывая завуалированные выражения, сленг и контекст (например ля, пидец, ауеть, ска и тд). Никакие оправдания "Ля это нота" и тд. вам не помогут.)</p>
	<p><b>3.7</b> Оскорбление проекта и администрации.</p>
	<span>* Распространяется на соц. сети.</span>
	<p><b>3.8</b> Афиширование багов, дюпов.</p>
	<span>* Игроки обязаны уведомить администрацию о найденном баге.</span>
	<p><b>3.9</b> Оскорбление родных.</p>
	<p><b>3.10</b> Организация флуда.</p>
	<p><b>3.11</b> Сообщения сексуального характера.</p>
	<p><b>3.12</b> Оскорбление чувств верующих.</p>
	<p><b>3.13</b> Пропаганда или агитация, возбуждающие социальную, расовую, национальную или религиозную ненависть и вражду, сообщения политического характера.</p>
	<p><b>3.13.1</b> Призыв к суициду, пропаганда запрещенных веществ или действий, распространение персональных данных других игроков без их согласия или угрозы распространения.</p>
	<span>* За распространение личных данных модераторов и/или администрации можно получить бан.</span>
	<span>* Разрешено сообщать данные для связи с модератором/администратором (VK, Discord)</span>
	<p><b>3.14</b> Систематическое нарушение правил, обычно влекущих за собой мут. Бан на 7 дней.</p>

	<h2>В мини-играх запрещено</h2>
	<p><b>4.1</b> Тим в соло режимах, создание помех во время игры.</p>
	<p><b>4.2</b> Запуск ботов / твинков на мини-игру.</p>
	<p><b>4.2.1</b> Запуск ботов на тотемы анархии.</p>
	<p><b>4.3</b> Буст в мини играх ради получения рейтинга / наград за рейтинг.</p>

	<h2>Правила для модераторов / хелперов:</h2>
	<p><b>5.1</b> При выдаче ban/mute/kick/warn модератор обязан иметь скриншот/видеозапись нарушения.</p>
	<p><b>5.2</b> Запрещено использование игровых полномочий во вред серверу.</p>
	<p><b>5.3</b> Запрещено использование игровых полномочий в личных целях.</p>
	<p><b>5.4</b> Адекватно вести себя, как в чате, так и в игре.</p>
	<p><b>5.5</b> Знать свои прямые обязанности и выполнять их.</p>
	<p><b>5.6</b> Соблюдать устав правил.</p>
	<p><b>5.7</b> Запрещено преждевременное снятие наказания.</p>

	<h2>Особое правило:</h2>
	<p><b>6.1</b> Любые спойлеры в группе, конфе или на сервере автоматически навлекают на вас гнев богов. Возможно лишение донатов, удаление острова, вечный бан везде где можно и нельзя и другие не очень приятные вещи. Не спойлерите.</p>
	<span>Работает 2 недели с выхода премьеры в России.</span>
</div>;

export default RulesPage;